<template>
  <div class="container">
    <div class="content">
      <div class="can">
        <img :src="showImg" mode="" />
      </div>
    </div>
    <div class="list">
      <div
        class="list-item"
        v-for="(item, index) in homeSlide"
        :key="index"
        :class="{ 'list-item-bor': isBor == index }"
        @click="scrHandler(index)"
      >
        <img :src="item.img" mode="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      homeSlide: [
        {
          img: require('@/assets/images/list(1).jpg'),
        },
        {
          img: require('@/assets/images/list(2).jpg'),
        },
        {
          img: require('@/assets/images/list(3).jpg'),
        },
        {
          img: require('@/assets/images/list(4).jpg'),
        },
        {
          img: require('@/assets/images/list(5).jpg'),
        },
        {
          img: require('@/assets/images/list(6).jpg'),
        },
        {
          img: require('@/assets/images/list(7).jpg'),
        },
        {
          img: require('@/assets/images/list(8).jpg'),
        },
      ],
      isBor: 0,
      showImg: require('@/assets/images/list(1).jpg'),
    };
  },
  methods: {
    autoHandler() {
      setInterval(() => {
        let imgLen = this.homeSlide.length;
        this.isBor++;
        if (this.isBor == imgLen) {
          this.isBor = 0;
        }
        this.scrHandler(this.isBor);
      }, 2000);
    },
    scrHandler(index) {
      this.isBor = index;
      this.showImg = this.homeSlide[index].img;
    },
  },
  created() {
    this.autoHandler();
  },
};
</script>

<style lang="scss" scoped>
page {
  height: 100%;
}

.container {
  display: flex;
  height: 100%;

  .content,
  .list {
    height: 100%;
  }

  .content {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/images/bor.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .can {
      width: 90%;
      height: 77%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .list {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .list-item-bor {
      border: 20rpx solid #3d96ff;
    }
    .list-item img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
