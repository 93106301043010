import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
// 处理重复点击相同路由报错
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/home',
      name: 'home',
      component: require('@/views/index/index').default,
    },
    {
      path: '/screenBind',
      name: 'screenBind',
      component: require('@/views/screenBind/screenBind').default,
    },
    {
      path: '/screen',
      name: 'screen',
      component: require('@/views/screen/screen').default,
    },
    {
      path: '/draw',
      name: 'draw',
      component: require('@/views/draw/draw').default,
    },
    {
      path: '/showpic',
      name: 'showpic',
      component: require('@/views/showpic/showpic').default,
    },
    {
      path: '*',
      redirect: '/home',
    },
  ],
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
