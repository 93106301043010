<template>
  <div>
    <input
      type="range"
      id="bar"
      min="1"
      max="50"
      step="1"
      v-model="rangeInfo.rangeValue"
      orient="vertical"
    />
  </div>
</template>
<script>
export default {
  props: ['rangeInfo'],
  methods: {
    touchstart(e) {
      console.log(e);
    },
  },
};
</script>
<style lang="scss" scoped>
input[type='range'] {
  -webkit-appearance: none;
  height: 2px;
}

input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  height: 15px;
  border-radius: 10px; /*将轨道设为圆角的*/
  // box-shadow: 0 1px 1px #def3f8, inset 0 0.125em 0.125em #0d1112; /*轨道内置阴影效果*/
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-top: -2px; /*使滑块超出轨道部分的偏移量相等*/
  background: #ffffff;
  border-radius: 50%; /*外观设置为圆形*/
  border: solid 0.125em rgba(205, 224, 230, 0.5); /*设置边框*/
  box-shadow: 0 0.125em 0.125em #3b4547; /*添加底部阴影*/
}

#bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
