import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: localStorage.getItem('language') || 'zh',
    platform: 'mobile',
    name: 'crw',
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem('language', language);
    },
  },
  actions: {},
  getters: {
    language(state) {
      return state.language;
    },
    platform() {
      // 判断手机端/pc
      let platform;
      if (navigator.userAgent.indexOf('Mobile') > -1) {
        platform = 'mobile';
      } else {
        platform = 'pc';
      }
      return platform;
    },
  },
});

export default store;
