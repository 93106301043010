import http from './http';

// 生成水墨画
export const createJob = (params) => {
  let headers = { 'Content-Type': 'multipart/form-data' };
  return http({
    url: '/api/shuimohua/createJob',
    method: 'post',
    data:params,
    headers,
  });
};

//保存水墨画原始数据
export const saveJobExtraData = (params) => {
  return http({
    url: '/api/shuimohua/saveJobExtraData',
    method: 'post',
    data:params,
  });
};


//获取水墨画状态
export const getJobState = (params) => {
  return http({
    url: '/api/shuimohua/getJobState',
    method: 'post',
    data:params,
    isQS: false,
  });
};

//获取水墨画结果
export const getJobInfo = (params) => {
  return http({
    url: '/api/shuimohua/getJobInfo',
    method: 'post',
    data:params,
    isQS: false,
  });
};

export const getJob = (params) => {
  return http({
    url: '/api/file/getJob',
    method: 'post',
    params,
  });
};

export const getPic = async (params) => {
  let headers = { 'Content-Type': 'multipart/form-data' };
  let res = await http({
    url: '/api/file/generatePic',
    method: 'post',
    params,
    headers,
    isQS: false,
  });
  let id = res.createJobResponse.job.id;
  // let headers = { 'Content-Type': 'multipart/form-data' };

  setTimeout(() => {
    return http({
      url: '/api/file/getJob',
      method: 'post',
      params: { jobId: id },
    });
  }, 15000);
};
