// **base64转图片文件方法**
export function toImgStyle(base64Str, fileName) {
  var arr = base64Str.split(','),
    mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
    bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
    len = bstr.length,
    u8arr = new Uint8Array(len); //
  while (len--) {
    u8arr[len] = bstr.charCodeAt(len);
  }
  // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
  // return 
  // let file = new File([u8arr], fileName, {
  //   type: mime,
  // });
  // return file
  let blob = new Blob([u8arr],{type:mime})
  blob.lastModifiedDate = new Date();
  blob.name = fileName
  return blob
}


export function isPc() {
  var userAgentInfo = navigator.userAgent;
  var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var flag = true;
  for (var i = 0; i < agents.length; i++) {
    if (userAgentInfo.indexOf(agents[i]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}