<template>
  <div class="showpic-container">
    <div class="showpic" :class="isPc() ? 'deg-0' : 'deg-90'">
      <div class="board">
        <div class="pic-box">
          <img :src="url" alt="" class="pic" id="pic" @load="loadImg(0)" />
          <!-- 落款区域 -->
          <div
            class="mask"
            :style="`width:${disArea.w}px;height:${disArea.h}px`"
          >
            <canvas id="canvas" class="canvas-active"></canvas>
          </div>
          <!-- 原简笔画 -->
          <img :src="canvasbg" alt="" id="canvas-bg" @load="loadImg(1)" />
          <!-- 笔势 -->
          <canvas id="canvas-pen" width="1600" height="900"></canvas>
        </div>
      </div>
      <!-- 二维码 -->
      <div class="cover" ref="code"></div>
      <img src="../../assets/images/showpic/tips.png" alt="" class="tips" />
      <!-- 按钮 -->
      <div class="newBtn">
        <img
          src="../../assets/images/showpic/resetDraw.png"
          alt=""
          class="resetDraw"
          @click="goback"
        />
        <img
          src="../../assets/images/showpic/toScreen.png"
          alt=""
          class="toScreen"
          @click="toNext"
        />
      </div>
      <!-- 音频 -->
      <audio id="audio" v-show="shuimohuaJob && shuimohuaJob.musicState==3" @ended="playVideo()"></audio>

    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import canvas from '../../assets/js/canvas'
import drawObj from '../../assets/js/drawObj'
import range from '../../components/range'
import { isPc } from '../../utils'
import popup from '../../components/popup'
import { Toast } from 'mint-ui'
import TransformUtil from '../../utils/TransformUtil.js'
import { constants } from 'os';

export default {
  data() {
    return {
      url: '',
      disArea: {},
      canvas: {},
      ctx: {},
      imgs: [],
      points: [],
      text_height: 0, //落款高度
      text_indent: null, //诗歌宽度，用于设置文字起始点
      shuimohuaId: 0,
      shuimohuaJob: null,
      canvasbg:'',
      audio:'',
      isPc:isPc
    }
  },
  created() {
    this.shuimohuaId = this.$route.query.shuimohuaId
    // let showpic = JSON.parse(sessionStorage.getItem('showpic'))
    // this.points = showpic.points
  },
  mounted() {
    this.getJobInfo()
  },
  destroyed () {
    this.audio=null
  },
  methods: {
    loadImg(e) {
      this.imgs.push(e)

      if (this.imgs.length >= 2) {
        let bg = document.getElementById('canvas-bg')
        bg.setAttribute('class', 'canvas-bg-active')
        this.showPenDraw(TransformUtil.getMaxYCoordinates(JSON.parse(this.shuimohuaJob.stickFigureData)))
        setTimeout(() => {
          bg.className = ''
          bg.style.display = 'none'
        }, 3000)
      }
    },
    async getJobInfo() {
      let job = await this.$api.getJobInfo({
        id:this.shuimohuaId
      })

      let {data} = job
      this.shuimohuaJob = data
      this.canvasbg = data.stickFigureUrl
      this.points = JSON.parse(data.stickFigureData)
      this.url = data.shuimohuaUrl
      this.init()
      this.initVideo()
      this.creatCanvas()
      this.creatQrCode()
    },
    init() {
      let showpic = document.getElementsByClassName('showpic')[0]
      showpic.style.width = window.innerHeight + 'px'

      let picBox = document.getElementsByClassName('pic-box')[0]
      let w = picBox.offsetWidth
      let h = picBox.offsetHeight
      // 禁止区域比例
      let disWstance = 2.57
      let disHstance = 2.33
      let disW = w / disWstance
      let disH = h / disHstance
      let disY = 0
      let disX = w - disW
      this.disArea = {
        w: disW,
        h: disH,
        x: disX,
        y: disY,
      }
      /*
       *canvas比例
       **/

      let scale = w / 1600
      this.canvas = document.getElementById('canvas')
      this.ctx = this.canvas.getContext('2d')
      this.canvas.style.transformOrigin = '0 0 0'
      this.canvas.style.webkitTransformOrigin = '0 0 0'
      this.canvas.width = this.disArea.w / scale
      this.canvas.height = this.disArea.h / scale
      this.canvas.style.transform = 'scale(' + scale + ',' + scale + ')'
      this.canvas.style.webkitTransform = 'scale(' + scale + ',' + scale + ')'
      // 原简笔画
      let canvasbg = document.getElementById('canvas-bg')
      canvasbg.style.transformOrigin = '0 0 0'
      canvasbg.style.webkitTransformOrigin = '0 0 0'
      canvasbg.style.transform =
        'scale(' + scale + ',' + scale + ') rotate(0deg) translatey(0%)'
      canvasbg.style.webkitTransform = 'scale(' + scale + ',' + scale + ') rotate(0deg) translateX(0%)'
      // 笔势canvas
      let canvaspen = document.getElementById('canvas-pen')
      canvaspen.style.transformOrigin = '0 0 0'
      canvaspen.style.webkitTransformOrigin = '0 0 0'
      canvaspen.style.transform =
        'scale(' + scale + ',' + scale + ') rotate(0deg) translateX(0%)'
      canvaspen.style.webkitTransform = 'scale(' + scale + ',' + scale + ') rotate(0deg) translateX(0%)'
    },
    initVideo(){
      let {musicState,musicStartTime,musicUrl} = this.shuimohuaJob
      if(musicState==3){
        this.audio = document.getElementById('audio')
        this.audio.setAttribute('src',musicUrl)
        this.playVideo()
      }
      document.addEventListener('visibilitychange', ()=> {
        if(document.hidden){
          if(this.audio){
            this.audio.pause()
          }
        }else {
          if(this.audio){
            this.audio.play()
          }
        }
      })
    },
    playVideo(){
      //iOS Safari可能是出于防止骚扰用户的考虑，首次非用户触发的play不会生效
      this.audio.currentTime = this.shuimohuaJob.musicStartTime
      this.audio.play()
      document.addEventListener("WeixinJSBridgeReady", function () {
        //微信H5环境，监听WeixinJSBridgeReady事件，再次进行play
        //亲测iOS 微信H5页面能够自动播放
          document.getElementById('audio').play();
      }, false)
    },
    creatCanvas() {
      this.createText1(this.shuimohuaJob.poetry)
    },
    creatQrCode(url) {
      let long = document.body.clientWidth>=768?65:50
      var qrcode = new QRCode(this.$refs.code, {
        text: window.location.href, // 获取当前路径
        width: long,
        height: long,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },
    /*
      绘制文字
      x,y文字开始的坐标
      name文本内容
      */
    ctxText(x, y, name) {
      this.text_height = y
      let letterSpacing = 10
      let ctx = this.ctx
      for (let i = 0; i < name.length; i++) {
        const str = name.slice(i, i + 1).toString()
        ctx.font = 'bold 32px XQzhaopaitiJF'
        ctx.fillStyle = 'white'
        if (str.match(/[A-Za-z0-9]/) && y < 576) {
          let letterSpacing = 30
          // 非汉字 旋转
          ctx.save()
          ctx.translate(x + Math.abs((letterSpacing - ctx.measureText(str).width))/2,y-10)
          ctx.textBaseline = 'top'
          ctx.fillText(str, 0, 0)
          ctx.restore()
          y +=letterSpacing // 计算文字宽度
          this.text_height += letterSpacing // 计算文字宽度
        } else if (str.match(/[\u4E00-\u9FA5]/) && y < 576) {
          ctx.save()
          ctx.textBaseline = 'top'
          ctx.fillText(str, x, y)
          ctx.restore()
          y += ctx.measureText(str).width + letterSpacing // 计算文字宽度
          this.text_height += ctx.measureText(str).width + letterSpacing // 计算文字宽度
        }
      }
    },
    createText() {
      let x = this.text_indent + 50, //根据诗歌位置调整落款位置
        y = 65,
        name = this.shuimohuaJob.sign
      this.ctxText(x, 15, name) //第一次拿落款高度
      //创建图片
      var img = new Image() // 创建一个<img>元素
      img.onload = () => {
        this.ctx.drawImage(img, x - 3, y - 15, 40, this.text_height) //绘制图片
        this.text_height = 0
        this.ctxText(x, y, name) //重绘
      }
      img.src = require('@/assets/images/落款.png') // 设置图片源地址
    },
    savePicture(Url){
        var blob=new Blob([''], {type:'application/octet-stream'});
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = Url;
        a.download = Url.replace(/(.*\/)*([^.]+.*)/ig,"$2").split("?")[0];
        var e = document.createEvent('MouseEvents');
        e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
        URL.revokeObjectURL(url);
    },
    createText1(str) {
      let ctx = this.ctx
      let y0 = 50
      let x,
        y = 50 // 文字开始的坐标
      let letterSpacing = 10 // 设置字间距
      ctx.font = '32px XQzhaopaitiJF'
      var text = str
        .replace(/[^\w^\s^\u4e00-\u9fa5]/gi, ',')
        .split(',')
        .slice(0, -1)
      x = text.length * 35 //根据诗歌每行宽度，设置文字绘制起点
      this.text_indent = x
      try {
        text.forEach((t, i, nt) => {
          y = y0
          let stArr = t.split('')
          stArr.forEach((s, index) => {
            ctx.save()
            ctx.textBaseline = 'top'
            ctx.fillText(s, x, y)
            ctx.restore()
            y += ctx.measureText(s).width + letterSpacing // 计算文字宽度
          })
          x = x - 34
          // 省略号
          if (x <= 32) {
            if (i < text.length - 1) {
              throw true
            } else {
              throw false
            }
          }
        })
      } catch (e) {
        y = y0
        x = x + 10
        if (e) {
          let dots = '......'.split('')
          dots.forEach((dot) => {
            ctx.save()
            ctx.textBaseline = 'top'
            ctx.fillText(dot, x, y)
            ctx.restore()
            y += ctx.measureText(dot).width + letterSpacing // 计算文字宽度
          })
        }
      }
      this.createText()
    },
    goback() {
      this.$router.go(-1)
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    save() {
      // let img = document.getElementById('pic');
      // let img = document.getElementById('test');
      let img = new Image()
      img.setAttribute('crossOrigin', 'Anonymous')
      img.src = this.url
      img.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = 1600
        canvas.height = 900
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        ctx.drawImage(this.canvas, 0, 0)
        var MIME_TYPE = 'image/png'
        var blob = this.dataURLtoBlob(canvas.toDataURL(MIME_TYPE))
        var imgURL = URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = imgURL
        a.download = ''
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      // let canvas = document.getElementById('canvas')
      // let canvas = document.getElementById('canvas1');
    },
    showPenDraw(coordinates) {
      let canvas = document.getElementById('canvas-pen')
      let ctx = canvas.getContext('2d')
      let img = new Image()
      img.src = require('@/assets/images/huabi.png')
      let arr = []
      this.points.forEach((item) => {
        arr.push(item)
      })
      let i = 0
      let s = 2000 / coordinates.length < 30 ? 2000 / coordinates.length : 30
      let timer = setInterval(() => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        let { x, y } = coordinates[i]
        this.isPc() ? ctx.drawImage(img, x, y-100, 150, 150) : ctx.drawImage(img, y, 800-x, 150, 150)
        
        i++
        if (i >= coordinates.length) {
          clearInterval(timer)
          i = 0
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          this.showCloud()
          return
        }
      }, s)
    },
    showCloud() {
      let montion = JSON.parse(this.shuimohuaJob.motionData)
      let cloudFrom = montion.cloud;
      let boatFrom = montion.boat;
      let canvas = document.getElementById('canvas-pen')
      let ctx = canvas.getContext('2d')
      let cloudImg = new Image()
      cloudImg.src = require('@/assets/images/cloud.png')
      let boatImg = new Image()
      boatImg.src = require('@/assets/images/boat.png')
      let arr = []
      this.points.forEach((item) => {
        arr.push(item)
      })
      let i = 0
      let timer = setInterval(() => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        if(cloudFrom.x > 0 && cloudFrom.y>0) {
          ctx.drawImage(cloudImg, cloudFrom.x-i, cloudFrom.y, 150, 150)
        }
        if(boatFrom.x > 0 && boatFrom.y>0) {
          ctx.drawImage(boatImg, boatFrom.x-200+i, boatFrom.y, 150, 150)
        }
        i++
        if (i >= 200) {
          i = 0
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          return
        }
      }, 100)
    },
    toNext() {
      // let canvas = this.getShotCanvas();
      var MIME_TYPE = 'image/png';
      var url = this.canvas.toDataURL(MIME_TYPE);
      this.savePicture(this.shuimohuaJob.shuimohuaUrl)
      // this.$router.push('/screenBind')
    },
  },
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounce1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#canvas-bg,
#canvas-pen {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  background: #fff;
}
#canvas-pen {
  background: transparent;
}
.canvas-bg-active {
  animation: bounce 3s linear;
  animation-fill-mode: forwards;
}
.canvas-active {
  animation: bounce1 3s linear;
  animation-fill-mode: forwards;
}
#canvas1 {
  z-index: 1000;
  background: #fff;
  position: absolute;
  top: 0;
  left: 100px;
  width: 100px;
  height: 100px;
}
#canvas {
  position: absolute;
  z-index: 1000;
  // background: #fff;
}
.showpic-container {
  width: 100%;
  // height: 100vh;
}
.showpic {
  background: url(../../assets/images/showpic/bg.png) no-repeat 100%;
  background-size: 100% 100%;
  position: relative;
  .event-btn {
    font-family: 'XQzhaopaitiJF';
    display: inline-block;
    position: relative;
    font-size: 0.4rem;
    margin: 0.266667rem 0 0 0.266667rem;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
    span {
      color: #fff;
      @include a-center;
      white-space: nowrap;
      margin-top: -0.08rem;
      margin-left: -0.053333rem;
    }
  }

  .board {
    // board123
    width: 72vh;
    height: calc(72vh * 9 / 16);
    position: absolute;
    top: 50%;
    left: 46%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background: url(../../assets/images/showpic/canvas.png) no-repeat 100%;
    background-size: 100% 100%;
    .pic-box {
      @include a-center;
      width: 67vh;
      height: calc(67vh * 9 / 16);
      // padding-bottom: 75%;
      // width: 14.48rem;
      // height: 8.145rem;
      border: 0.026667rem solid #8c817a;
      overflow: hidden;
      .pic {
        width: 100%;
        height: 100%;
      }
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
<style scoped lang="scss">
.cover {
  position: absolute;
  // width: 1.333333rem;
  // height: 1.333333rem;
  right: 0.48rem;
  top: 1.466667rem;
  background-color: #fff;
  border: 0.053333rem solid #fff;
  // border-bottom: 0;
}
.tips {
  position: absolute;
  width: 0.96rem;
  height: 2.706667rem;
  top: 3.133333rem;
  right: 0.666667rem;
}
.newBtn {
  position: absolute;
  display: flex;
  display:-webkit-flex;
  align-items: center;
  -webkit-align-items:center;
  bottom: 0.133333rem;
  right: 2.453333rem;
  .resetDraw,
  .toScreen {
    width: 2.56rem;
    height: 0.8rem;
  }
  .toScreen {
    margin-left: 0.733333rem;
  }
}
</style>
