import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';

// axios.defaults.baseURL = '/api';
axios.defaults.timeout = 30 * 1000 * 1000; //超时时间
axios.defaults.headers['Content-Type'] =
  'application/json;charset=UTF-8';

export default function http(obj) {
  return new Promise((reslove,reject)=>{
    return axios(obj).then(res=>{
      reslove(res.data)
    }).catch(err=>{
      reject(err)
    })
  })
}
