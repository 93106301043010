export const computedBox = function(w_p = 1,h_p = .9){
  let cw =  document.body.clientWidth //当前屏幕大小 
  let ch = document.body.clientHeight
  let step = 0.01
  let height_percent = h_p
  let width_percent = w_p
  let w,h
  let computedStr = 'w = cw * width_percent;h = w * 16 / 9'
  w = cw * width_percent
  h = w * 16 / 9
  // 1.手机上最大的16:9
  while(w >= cw || h >=ch){
    width_percent -= step
    eval(computedStr) //eval很耗性能
  }
  // 2.
  if(w==cw*(1-step)){
    // alert('宽到边了')
    width_percent = 0.78 // 写死
    eval(computedStr)
  }
  if(h + 5>ch){  // 5为大概范围
    let effectiveHeight = ch * height_percent
    while(effectiveHeight< h){
      width_percent -= step*2
      eval(computedStr)
    }
  }
  return {w,h}
}