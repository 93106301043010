export default class drawObj {
  constructor(type, pixel) {
    this.points = [];
    this.pixel = pixel;
    this.type = type; //1画笔 0橡皮擦
  }
  pushPoint(point) {
    this.points.push(point);
    if (this.points.length == 1) {
      this.firstPoint = { x: point.x, y: point.y };
      this.lastPoint = { x: point.x, y: point.y };
    } else {
      this.firstPoint = {
        x: Math.min(this.firstPoint.x, point.x),
        y: Math.min(this.firstPoint.y, point.y),
      };
      this.lastPoint = {
        x: Math.max(this.lastPoint.x, point.x),
        y: Math.max(this.lastPoint.y, point.y),
      };
    }
  }
  drawWithCanvasAll(ctx, type = this.type) {
    if (this.points.length < 1) {
      return;
    }
    let oGC = ctx;
    if (type == 0) {
      oGC.closePath();
      oGC.beginPath();
      oGC.save();
      oGC.lineWidth = this.pixel;
      oGC.globalCompositeOperation = 'destination-out';
      oGC.strokeStyle = 'rgba(0,0,0,1)';
      oGC.lineCap = 'round';
      oGC.lineJoin = 'round';

      let startx, starty;
      var points = this.points;
      oGC.moveTo(points[0].x, points[0].y);
      if (points.length > 1) {
        points.forEach(function(point, index) {
          let x = point.x;
          let y = point.y;
          if (index == 0) {
            oGC.moveTo(point.x, point.y);
          } else {
            let midX = (startx + x) / 2;
            let midY = (starty + y) / 2;
            oGC.bezierCurveTo(startx, starty, midX, midY, x, y);
          }
          startx = x;
          starty = y;
        });
      }
      oGC.stroke();
      oGC.restore();
      oGC.closePath();
    } else if (type == 1) {
      let points = this.points;
      if (this.points.length < 1) {
        return;
      }
      oGC.closePath();
      oGC.beginPath();
      oGC.save();
      oGC.lineWidth = this.pixel;

      oGC.strokeStyle = '#000';
      // 在源图上
      oGC.globalCompositeOperation = 'source-over';

      oGC.lineCap = 'round';
      oGC.lineJoin = 'round';
      oGC.moveTo(points[0].x, points[0].y);

      if (points.length > 1) {
        points.forEach(function(point, i, a) {
          if (i < a.length - 2) {
            let [midX, midY] = [
              (point.x + a[i + 1].x) / 2,
              (point.y + a[i + 1].y) / 2,
            ];
            oGC.quadraticCurveTo(point.x, point.y, midX, midY);
          }
        });
      }
      oGC.stroke();
      oGC.restore();
      oGC.closePath();
    }
  }
}
