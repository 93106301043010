<template>
  <div class="home-container">
    <div class="bg">
      <!-- <div class="btns">
        <div class="btn-b" @click="goto('/screenBind')">
          <span>{{ $t('index-btn1') }}</span>
        </div>
        <div @click="goto('/draw')">
          <span>{{ $t('index-btn2') }}</span>
        </div>
        <div class="language-btn">
          <span @click="changeLanguage">{{ $t('btn') }}</span>
        </div>
      </div> -->
      <img src="../../assets/images/index/title.png" alt="" class="title" />
      <img
        src="../../assets/images/index/start.png"
        alt=""
        class="start"
        @click="goto('/draw')"
      />
      <div class="tip">
        <img
          src="../../assets/images/index/kuang.png"
          alt=""
          class="kuang"
          @click="arrowFlag = true"
        />
        <img
          src="../../assets/images/index/arrow.png"
          alt=""
          class="arrow"
          @click="arrowFlag = false"
          v-show="arrowFlag"
        />
        <span class="tips" @click="arrowFlag=!arrowFlag">我已同意腾讯水墨画《用户协议》</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      title: 'Hello',
      info: {},
      arrowFlag: true,
    }
  },
  onLoad() {},
  beforeCreate() {},
  created() {},
  mounted() {
    let h = window.innerHeight
    // let h = document.body.clientHeight;
    let home = document.getElementsByClassName('home-container')[0]
    home.style.height = h + 'px'
    let ih = 1624
    let bt = 1120
    let mt = 50
    let mt1 = 70

    let nbt = (h * bt) / ih
    let nmt = (h * mt) / ih
    let nmt1 = (h * mt1) / ih

    this.info = {
      nbt,
      nmt,
      nmt1,
    }
  },
  computed: {
    ...mapGetters(['language']),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    changeLanguage() {
      if (this.language == 'en') {
        this.setLanguage('zh')
      } else {
        this.setLanguage('en')
      }
    },
    goto(path) {
      if (this.arrowFlag) {
        this.$router.push(path)
      } else {
        this.$toast({
          message: '请勾选喔！',
          position: 'top',
          duration: 1000,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.home-container {
  background-color: #808080;
  // height: 100vh;
  height: 100vh;

  width: 100%;
}

.bg {
  height: 100%;
  width: 100%;
  background: url(../../assets/images/index/home_bg.png) no-repeat;
  // background: oldlace;
  background-size: 100% 100%;
  // background-size: 100% 21.653333rem;
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 100%;
  // background: url(http://imagecdn0.17huo.com/o_1bvue57hgbp8ms1diu18ctjnrh.jpg)
  //   no-repeat center;
  background-size: 100% 100%;
  .title {
    position: absolute;
    top: 1.226667rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    height: 4.173333rem;
    width: 3.066667rem;
  }
  .start {
    position: absolute;
    bottom: 4.013333rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    height: 1.2rem;
    width: 4.493333rem;
  }
  .tip {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    position: absolute;
    bottom: 2.333333rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    .kuang {
      width: 0.573333rem;
      height: 0.56rem;
      margin-right: 0.12rem;
    }
    .arrow {
      position: absolute;
      left: 0.066667rem;
      top: 0.053333rem;
      width: 0.453333rem;
      height: 0.453333rem;
    }
    .tips {
      height: 0.56rem;
      line-height: 0.56rem;
      font-size:.45rem;
      font-family: 'XQzhaopaitiJF';
      font-weight: 400;
      color: #333333;
      width: 6.5rem;
    }
  }
}

.btns {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0.4rem;
  > div {
    width: 7.2rem;
    height: 1.92rem;
    line-height: 1.92rem;
    text-align: center;
    color: rgba(80, 80, 80, 1);
    font-size: 0.373333rem;
    // background-color: #fff;
    border-radius: 0.426667rem;
    // box-shadow: 0px 0.053333rem 0.053333rem 0px rgba(0, 0, 0, 0.5);
    border: 0;
    background: url(../../assets/images/btnbg.png) no-repeat;
    background-size: 100% 100%;
    font-family: 'XQzhaopaitiJF';
    span {
      top: -0.106667rem;
      font-size: 0.48rem;
      position: relative;
    }
  }
  .language-btn {
    // position: absolute;
    width: 5.4rem;
    height: 1.933333rem;
    margin-top: 0;
    background: url(../../assets/images/language.png) no-repeat;
    background-size: 100% 100%;
    // z-index: -1;
    bottom: 0;
  }
  .btn-b {
    // margin-bottom: 0.826667rem;
  }
}
.language {
  position: absolute;
  bottom: 0.373333rem;
  right: 0.56rem;
  color: rgba(80, 80, 80, 1);
  font-size: 0.373333rem;
}
.btn {
  position: absolute;
  bottom: 0.266667rem;
  right: 0.266667rem;
  font-size: 0.373333rem;
  color: rgba(80, 80, 80, 1);
}
</style>
