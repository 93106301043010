<template>
  <div class="screen-bind">
    <p class="title">{{ $t('screen-bind-title') }}</p>
    <div class="number">
      <div
        v-for="(item, index) in boxs"
        :key="index"
        class="box flex-c"
        :class="{ cursorActive: inputInfo.cursorIndex == index }"
        @click.stop="clickFocus"
      >
        <span>{{ inputInfo.NumberArr[index] }}</span>
      </div>
      <input
        type="number"
        class="input"
        maxlength="2"
        id="text-box"
        v-model="inputInfo.number"
        @input="input(inputInfo.number)"
      />
    </div>
    <div class="btn-box">
      <mt-button class="btn">
        {{ $t('screen-bind-btn') }}
      </mt-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxs: 6,
      inputInfo: {
        number: '',
        cursorIndex: 0,
        NumberArr: [],
      },
    }
  },
  computed: {},
  mounted() {
    this.clickFocus()
  },
  methods: {
    input(number) {
      if (number.length > 6) {
        number = number.slice(0, 6)
        this.inputInfo.number = number
      }

      this.inputInfo.NumberArr = String(number).split('')
      let nl = this.inputInfo.NumberArr.length
      nl == this.boxs
        ? (this.inputInfo.cursorIndex = this.boxs - 1)
        : (this.inputInfo.cursorIndex = nl)
    },
    clickFocus() {
      let input = document.getElementById('text-box')
      input.focus()
    },
  },
}
</script>

<style scoped lang="scss">
.screen-bind {
  .title {
    margin-top: 4.16rem;
    color: rgba(80, 80, 80, 1);
    font-size: 0.533333rem;
    line-height: 150%;
    text-align: center;
    font-weight: bold;
  }

  .number {
    margin-top: 1.146667rem;
    margin-bottom: 5.253333rem;
    display: flex;
    justify-content: space-between;
    padding: 0 0.16rem;
    position: relative;

    // overflow: hidden;
    .input {
      position: absolute;
      opacity: 0;
    }

    .box {
      width: 1.386667rem;
      height: 1.386667rem;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.426667rem;
      font-size: 0.64rem;
      line-height: 150%;
      border: rgba(0, 0, 0, 1) solid 0.026667rem;
    }

    .cursorActive {
      border: 0.026667rem solid red;
    }
  }

  .btn-box {
    display: flex;
    justify-content: center;
    .btn {
      border: rgba(76, 76, 76, 1) solid 0.026667rem;
      width: 7.146667rem;
      height: 1.12rem;
      line-height: 1.12rem;
      // text-align: center;
      color: rgba(80, 80, 80, 1);
      font-size: 28rpx;
      background-color: #fff;
      border-radius: 32rpx;
      box-shadow: 0px 0.053333rem 0.053333rem 0px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
