import Vue from 'vue';
import App from './App.vue';
import i18n from './language/i18n';
import router from './router';
import store from './store/index.js';
import 'lib-flexible';
import api from './apis';
import { Button, MessageBox, Indicator,Toast,Field  } from 'mint-ui';
Vue.component(Button.name, Button);
import { Range } from 'mint-ui';
Vue.component(Range.name, Range);
Vue.component(Field.name, Field);
// import fdk from './lib/fdk/source/fdk.js';
// Vue.prototype.$fdk = fdk;
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$toast = Toast;
Vue.prototype.$Indicator = Indicator;

new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
