//此Util只包含数据转换通用方法，不含开发平台类型

function quickSortDrawObj(arr) {
    if (arr.length <= 1) { 
        return arr; 
    }
    var pivotIndex = Math.floor(arr.length / 2);
    var pivot = arr.splice(pivotIndex, 1)[0];
    var left = [];
    var right = [];
    for (var i = 0; i < arr.length; i++){
        if (arr[i].y < pivot.y) {
            left.push(arr[i]);
        } else {
            right.push(arr[i]);
        }
    }
    return quickSortDrawObj(left).concat([pivot], quickSortDrawObj(right));
}

export default {
    //获取最大Y轴坐标
    getMaxYCoordinates:function(coordinates){
        console.log(coordinates);
        let arr = [];
        coordinates.forEach((drawObj) => {
            arr = [...arr, ...drawObj.points];
        });
        // let retPoints = [];
        // let drawObjArray = quickSortDrawObj(arr);
        // let MaxPointCount = 100;
        // console.log(drawObjArray.length)
        // let splitCount = Math.floor(drawObjArray.length/100);
        // console.log(splitCount)
        // if(drawObjArray.length > MaxPointCount){
        //     for(let i=0; i<MaxPointCount; i++) {
        //         retPoints.push(drawObjArray[i*splitCount]);
        //     }
        // }
        // else {
        //     return arr;
        // }
        // console.log(retPoints);
        
        return arr;
    }
}