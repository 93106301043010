<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Toast } from 'mint-ui'

export default {
  name: 'App',
  components: {},
  computed: {
    ...mapGetters(['language']),
  },
  created() {
    let a = navigator.userAgent
  },
  mounted() {
    window.addEventListener('orientationchange', function(e) {
      // orient();
      Toast('请关闭屏幕旋转')

      if (window.orientation == 90 || window.orientation == -90) {
        //横屏
        // Toast('横屏');
        // let a = document.getElementsByClassName('board')[0]
        // a.className = 'board'
        // let box = document.getElementById('box')
        // box.style.transform = 'rotate(0deg)'
      } else {
        //竖屏
        // Toast('竖屏');
        window.location.reload()
      }
    })
  },
  watch: {
    language: {
      handler: function(t) {
        this.$i18n.locale = t
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import './assets/css/common.scss';
@import './assets/css/mint.scss';

/* 判断ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* style */
  html {
    // font-size: 50px !important;
  }
  .home-container {
    .bg {
      background: url(./assets/images/index/home_bg.png) no-repeat !important;
      background-size: 100% 100% !important;
    }
  }
  .draw-container {
    .board {
      background: url(./assets/images/draw/bg.png) no-repeat 100% !important;
      background-size: 100% 100% !important;
    }
  }
}

@font-face {
  font-family: 'XQzhaopaitiJF';
  src: url('./assets/font/xqzpt.ttf?#font-spider') format('truetype');
  font-display: swap;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'XQzhaopaitiJF' !important;
  height: 100vh !important;// 为了可以获取高度
}
</style>
