import drawObj from './drawObj';
import { isPc } from '../../utils'

// 暂时还没优化，
export default class canvas {
  constructor(canvasBox, canvasBoxW, canvasBoxH, w, h) {
    this.hbimg = new Image();
    this.hbimg.src = require('@/assets/images/huabi.png');
    let canvas = document.createElement('canvas');
    canvasBox.appendChild(canvas);
    this.type = 1; //1画笔 0橡皮擦
    this.pixel = 15;
    this.canvas = canvas;
    this.objectIdCounter = 1;
    this.disArea = [];
    this.canvas.id = 'canvas';
    this.canvas.width = w;
    this.canvas.height = h;
    this.canvas.style.position = 'absolute';
    this.canvas.style.background = 'transparent';
    this.canvas.style.transformOrigin = '0 0 0';
    this.canvas.style.msTransformOrigin = '0 0 0';
    this.canvas.style.webkitTransformOrigin = '0 0 0';
    this.canvasBox = canvasBox;
    this.setScale(canvasBoxW, canvasBoxH, w, h);
    this.ctx = canvas.getContext('2d');
    this.ctx.fillStyle = 'transparent';
    // this.img.onload = () => {
    //   this.ctx.drawImage(this.img, 0, 0, 900, 1600);
    // };
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawObjArr = []; //每一笔的对象
    this.touchTop = -1; //canvas偏移量，修正点击时正确canvas的x,y坐标
    this.touchLeft = -1;
    let that = this;
    this.msg = '';
    this.putDrawPoints = [];
    this.undoArray = [];
    this.redoArray = [];
    // 操作的状态
    let statusConfig = {
      idle: 0,
      draw_start: 1,
      drawing: 2,
      move_start: 3,
      moving: 4,
      scale_start: 5,
      scaleing: 6,
      double: 7,
    };
    // 画布操作缩放
    this.canvasInfo = {};
    this.scale = 1;
    this.maxScale = 3; // 最大缩放值
    this.minScale = 1; // 最小缩放值
    this.step = 0.1; // 缩放率
    this.offsetX = 0; // 画布X轴偏移值
    this.offsetY = 0; // 画布Y轴偏移值
    let targetX, targetY, mousedownOriginX, mousedownOriginY;
    let start, timer;

    // 按下======
    canvas.onmousedown = function(event) {
      that.eventState = 1;
      that.canvasInfo.status = statusConfig.draw_start;
      that.touchLeft = canvas.getBoundingClientRect().left;
      that.touchTop = canvas.getBoundingClientRect().top;
      // 新建对象
      that.drawingObject = new drawObj(that.type, that.pixel);
      that.drawingObject.objectId = that.objectIdCounter++;
      that.drawObjArr.push(that.drawingObject);
    };
    canvas.ontouchstart = function(event) {
      that.eventState = 1;
      that.canvasInfo.status = statusConfig.draw_start;
      that.touchLeft = canvas.getBoundingClientRect().left;
      that.touchTop = canvas.getBoundingClientRect().top;
      // 新建对象
      that.drawingObject = new drawObj(that.type, that.pixel);
      that.drawingObject.objectId = that.objectIdCounter++;
      that.drawObjArr.push(that.drawingObject);
      if (event.touches && event.touches.length == 2) {
        // 双指
        that.canvasInfo.status = statusConfig.move_start;
        //记录初始 一组数据 作为缩放使用
        start = event.touches; //得到第一组两个点
      }
    };

    // 拖动==========
    canvas.onmousemove =function(e) {
        if (that.canvasInfo.status === statusConfig.draw_start) {
          that.msg = 'draw_start';
  
          that.canvasInfo.status = statusConfig.drawing;
        } else if (that.canvasInfo.status === statusConfig.drawing) {
          that.msg = 'drawing';
          let offsetX = e.clientX - that.touchLeft;
          let offsetY = e.pageY - that.touchTop;
          var x = (offsetX / that.canvasScale - that.offsetX) / that.scale;
          var y = (offsetY / that.canvasScale - that.offsetY) / that.scale;
  
          that.drawingObject.pushPoint({ x, y });
          that.render(that.ctx);
        } else if (that.canvasInfo.status === statusConfig.move_start) {
          that.canvasInfo.status = statusConfig.moving;
          let offsetX = e.clientX - that.touchLeft;
          let offsetY = e.pageY - that.touchTop;
          targetX = offsetX;
          targetY = offsetY;
          mousedownOriginX = that.offsetX;
          mousedownOriginY = that.offsetY;
        } else if (that.canvasInfo.status === statusConfig.moving) {

          let offsetX = e.clientX - that.touchLeft;
          let offsetY = e.pageY - that.touchTop;
          that.offsetX = mousedownOriginX + offsetX - targetX;
          that.offsetY = mousedownOriginY + offsetY - targetY;
          that.render(that.ctx);
        } 
    };
    canvas.ontouchmove = function(e) {
      if (e.touches.length == 2) {
        if (
          Math.abs(
            that.getDistance(e.touches[0], e.touches[1]) -
              that.getDistance(start[0], start[1])
          ) < 5 &&
          that.canvasInfo.status != statusConfig.moving
        ) {
          that.canvasInfo.status = statusConfig.move_start;
        } else if (
          Math.abs(
            that.getDistance(e.touches[0], e.touches[1]) -
              that.getDistance(start[0], start[1])
          ) >= 5 &&
          that.canvasInfo.status != statusConfig.scaleing
        ) {
          that.canvasInfo.status = statusConfig.scaleing;
        }
      } else if (e.touches.length == 1) {
        that.canvasInfo.status = statusConfig.drawing;
      }
      if (that.canvasInfo.status === statusConfig.draw_start) {
        that.msg = 'draw_start';

        that.canvasInfo.status = statusConfig.drawing;
      } else if (that.canvasInfo.status === statusConfig.drawing) {
        that.msg = 'drawing';
        let offsetX = e.touches[0].clientX - that.touchLeft;
        let offsetY = e.touches[0].pageY - that.touchTop;
        var x = (offsetX / that.canvasScale - that.offsetX) / that.scale;
        var y = (offsetY / that.canvasScale - that.offsetY) / that.scale;

        that.drawingObject.pushPoint({ x, y });
        that.render(that.ctx);
      } else if (that.canvasInfo.status === statusConfig.move_start) {
        that.canvasInfo.status = statusConfig.moving;
        let offsetX = e.touches[0].clientX - that.touchLeft;
        let offsetY = e.touches[0].pageY - that.touchTop;
        targetX = offsetX;
        targetY = offsetY;
        mousedownOriginX = that.offsetX;
        mousedownOriginY = that.offsetY;
      } else if (that.canvasInfo.status === statusConfig.moving) {
        let offsetX = e.touches[0].clientX - that.touchLeft;
        let offsetY = e.touches[0].pageY - that.touchTop;
        that.offsetX = mousedownOriginX + offsetX - targetX;
        that.offsetY = mousedownOriginY + offsetY - targetY;
        that.render(that.ctx);
      } else if (that.canvasInfo.status === statusConfig.scaleing) {
        var now = e.touches;

        that.msg = 'scaleing' + JSON.stringify(now[0].clientX);

        let x =
          (now[0].clientX -
            that.touchLeft +
            (now[1].clientX - that.touchLeft)) /
            2 /
            that.canvasScale -
          that.offsetX;
        let y =
          (now[0].clientY - that.touchTop + (now[1].clientY - that.touchTop)) /
            2 /
            that.canvasScale -
          that.offsetY;
        var offsetX = (x / that.scale) * that.step;
        var offsetY = (y / that.scale) * that.step;
        // 放大

        if (
          that.getDistance(now[0], now[1]) <
          that.getDistance(start[0], start[1])
        ) {
          // 缩小
          that.msg = '缩小' + that.scale;

          that.offsetX += that.scale <= that.minScale ? 0 : offsetX;
          that.offsetY += that.scale <= that.minScale ? 0 : offsetY;
          that.scale -= that.step;
        } else if (
          that.getDistance(now[0], now[1]) >
          that.getDistance(start[0], start[1])
        ) {
          that.msg = '放大' + that.scale;

          // 放大
          that.offsetX -= that.scale >= that.maxScale ? 0 : offsetX;
          that.offsetY -= that.scale >= that.maxScale ? 0 : offsetY;
          that.scale += that.step;
        }
        start = now;

        that.scale = Math.min(
          that.maxScale,
          Math.max(that.scale, that.minScale)
        );
        that.render(that.ctx);
      }
    };
    // 结束==========
    document.onmouseup = function(ev) {

      if(that.canvasInfo.status === statusConfig.drawing){
        that.canvasInfo.status = statusConfig.idle;
        clearTimeout(timer);
        that.msg = 'END';

        that.unArrayPush(that.drawingObject);
      
        that.drawingObject = null;
        console.log(that, '=========end');
      }
      
      
    };
    canvas.ontouchend = function(ev) {
      that.canvasInfo.status = statusConfig.idle;
      clearTimeout(timer);
      that.msg = 'END';
      that.unArrayPush(that.drawingObject);

      that.drawingObject = null;
      console.log(that, '=========end');
    };
    // 滚轮缩放
    // canvas.addEventListener('wheel', (e) => {
    //   //滚轮放大缩小
    //   if (e.wheelDelta) {
    //     var x = e.offsetX - that.offsetX;
    //     var y = e.offsetY - that.offsetY;

    //     var offsetX = (x / that.scale) * that.step;
    //     var offsetY = (y / that.scale) * that.step;

    //     if (e.wheelDelta > 0) {
    //       that.offsetX -= that.scale >= that.maxScale ? 0 : offsetX;
    //       that.offsetY -= that.scale >= that.maxScale ? 0 : offsetY;

    //       that.scale += that.step;
    //     } else {
    //       that.offsetX += that.scale <= that.minScale ? 0 : offsetX;
    //       that.offsetY += that.scale <= that.minScale ? 0 : offsetY;

    //       that.scale -= that.step;
    //     }

    //     that.scale = Math.min(
    //       that.maxScale,
    //       Math.max(that.scale, that.minScale)
    //     );

    //     that.render(that.ctx);
    //   }
    // });
  }

  render(ctx = this.ctx) {
    // 清除画板
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // 保证  offsetX  在  [canvas.width*(1-scale),0]   区间内
    if (this.offsetX < this.canvas.width * (1 - this.scale)) {
      this.offsetX = this.canvas.width * (1 - this.scale);
    } else if (this.offsetX > 0) {
      this.offsetX = 0;
    }
    // 保证  offsetY   在  [canvas.height*(1-scale),0]   区间内
    if (this.offsetY < this.canvas.height * (1 - this.scale)) {
      this.offsetY = this.canvas.height * (1 - this.scale);
    } else if (this.offsetY > 0) {
      this.offsetY = 0;
    }
    // 缩放
    ctx.setTransform(this.scale, 0, 0, this.scale, this.offsetX, this.offsetY);

    // 画画
    this.drawObjArr.forEach((obj) => {
      obj.drawWithCanvasAll(ctx);
    });
  }
  dImg(ctx) {
    ctx.drawImage(this.img, 0, 0);
  }
  //缩放 勾股定理方法-求两点之间的距离
  getDistance(p1, p2) {
    var x = p2.pageX - p1.pageX,
      y = p2.pageY - p1.pageY;
    return Math.sqrt(x * x + y * y);
  }
  /*坐标转换*/
  windowToCanvas(x, y) {
    var box = this.canvas.getBoundingClientRect(); //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离

    return {
      x: x - box.left - (box.width - this.canvas.width) / 2,
      y: y - box.top - (box.height - this.canvas.height) / 2,
    };
  }

  //缩放
  setScale(boxWidth, boxHeight, canvasWidth, canvasHeight) {
    var scaleNum;
    var scaleWidth = boxWidth / canvasWidth;
    var scaleHeight = boxHeight / canvasHeight;
    if (scaleWidth > scaleHeight) {
      scaleNum = boxHeight / canvasHeight;
    } else {
      scaleNum = boxWidth / canvasWidth;
    }

    this.canvasScale = scaleNum;
    /* -标准语法 -ms-兼容IE9 -webkit-兼容Safari*/
    this.canvas.style.transform = 'scale(' + scaleNum + ',' + scaleNum + ')';
    this.canvas.style.msTransform = 'scale(' + scaleNum + ',' + scaleNum + ')';
    this.canvas.style.webkitTransform =
      'scale(' + scaleNum + ',' + scaleNum + ')';
    this.canvas.style.marginLeft =
      (boxWidth - canvasWidth * scaleNum) / 2 + 'px';
    this.canvas.style.marginTop =
      (boxHeight - canvasHeight * scaleNum) / 2 + 'px';
  }

  // 设置像素比
  setRatio() {
    let d = window.devicePixelRatio || 1;

    let cd = this.ctx.backingStorePixelRatio || 1;
    let r = d / cd;
    let w = this.canvas.width;
    let h = this.canvas.height;
    this.canvas.width = r * w;
    this.canvas.height = r * h;

  }
  initDisArea(disArea) {
    let ndisArea = JSON.parse(JSON.stringify(disArea));
    // 传过来的坐标要转换为canvas真实坐标，需要进行缩放
    for (let key in ndisArea) {
      ndisArea[key] = ndisArea[key] / this.canvasScale;
    }
    this.disArea = ndisArea;

    // arr [x,y,w,h]
  }
  // 禁止区域
  // 可以再去除掉禁止区域的points，还未做
  clearDisArea() {
    if (this.disArea) {
      this.ctx.clearRect(this.disArea.x, this.disArea.y, this.disArea.w, this.disArea.h);
    }
  }
  getCopyCanvas(){
    let canvas = document.createElement('canvas')
    canvas.width = 900
    canvas.height = 1600
    let ctx = canvas.getContext('2d')
    ctx.drawImage(this.canvas,0,0)
    return canvas
  }
  getAllPoints(){
    let allPoints = []
    this.drawObjArr.forEach(item=>{
      allPoints.push(item.points)
    })
    return allPoints
  }
  // 逆时针旋转90度生成canvas
  getShotCanvas(fn) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    if(isPc()){
      let imgw = this.canvas.width;
      let imgh = this.canvas.height;

      canvas.width = imgw;
      canvas.height = imgh;

      ctx.fillStyle = '#d7d4cd';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 将原始canvas的内容绘制到新canvas上
      ctx.drawImage(this.canvas, 0, 0);

      return canvas;
    }else{
      this.scale = 1;
      this.offsetX = 0;
      this.offsetY = 0;
      this.ctx.setTransform(
        this.scale,
        0,
        0,
        this.scale,
        this.offsetX,
        this.offsetY
      );
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.reloadObjects();
      // this.clearDisArea()
      var that = this;
      // var canvas = document.createElement('canvas');
      // var ctx = canvas.getContext('2d');

      // 裁剪坐标
      let cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 };
      let imgw = this.canvas.width;
      let imgh = this.canvas.height;
      // 哪边长按哪边为半径生成矩形
      let size = imgw > imgh ? imgw : imgh;
      // 画布大小
      canvas.height = canvas.width = size * 2;
      // canvas.height = size * 2;
      // 画布上要截取的元素点
      cutCoor.sx = size;
      cutCoor.sy = size - imgw;
      cutCoor.ex = size + imgh;
      cutCoor.ey = size + imgw;
      // 移动到中心
      ctx.translate(size, size);
      //旋转-90
      ctx.rotate((270 * Math.PI) / 180);
      // 把画 画到新画布上
      ctx.drawImage(that.canvas, 0, 0);
      // 截取画布坐标点上的元素
      let imgData = ctx.getImageData(
        cutCoor.sx,
        cutCoor.sy,
        cutCoor.ex,
        cutCoor.ey
      );
      // 设置新画布大小
      canvas.width = imgh;
      canvas.height = imgw;

      // // 渲染到新画布上

      ctx.putImageData(imgData, 0, 0);
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#d7d4cd';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      //======================???背景如果是要图片，如何同步
      // let img = new Image();
      // img.src = require('../xxx.jpg');

      // img.onload = () => {
      //   ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      //   return fn(canvas, this);
      // };

      return canvas;

    }
    
  }
  // 处理成blob，否则图片太大可能下载失败
  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  getImageUrl() {
    let canvas = this.getShotCanvas();

    var MIME_TYPE = 'image/png';
    var url = canvas.toDataURL(MIME_TYPE);
    return url;
  }
  downloadImage() {
    // console.log(canvas, '=============206', that);

    // var MIME_TYPE = 'image/png';
    // let canvas = this.getShotCanvas();
    // let imgURL = canvas.toDataURL(MIME_TYPE);

    let canvas = this.getShotCanvas();
    var MIME_TYPE = 'image/png';
    var blob = this.dataURLtoBlob(canvas.toDataURL(MIME_TYPE));
    var imgURL = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = imgURL;
    a.download = '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  undo() {
    if (this.undoArray.length == 0) {
      return null;
    }
    // this.clearPage();
    var object = this.undoArray.pop();
    this.drawObjArr.pop()
    this.redoArray.push(object);
    // this.reloadObjects();
    this.render();
    // this.saveCache();
    return object;
  }
  unArrayPush(object) {
    if (object.points.length > 1) {
      this.undoArray.push(object);
    } else {
      this.drawObjArr.pop();
    }
  }
  redo() {
    if (this.redoArray.length == 0) {
      return null;
    }

    var object = this.redoArray.pop();
    this.drawObjArr.push(object);
    // this.unArrayPush(object);
    this.undoArray.push(object);

    this.render();
    // this.reloadObjects();
    // this.saveCache();
    return object;
  }

  clearRedoArray() {
    this.redoArray.splice(0, this.redoArray.length);
  }
  saveCache() {
    this.canvasCache = this.canvas
      .getContext('2d')
      .getImageData(0, 0, this.canvas.width, this.canvas.height);
  }
  clearPage() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawObjArr = [];
    this.undoArray = [];
    this.redoArray = [];
    // console.log(this);
  }

  //重新渲染所有图形
  reloadObjects() {
    //
    var that = this;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.drawObjArr.forEach(function(object, i, a) {
      if (object) {
        object.drawWithCanvasAll(that.ctx);
      }
    });
  }
  // 渲染指定的点
  loadPoints(points) {
    // let { x: x1, y: y1 } = arr[i + 1];
    // console.log(x, y, x1, y1);
    // oGC.closePath();
    // oGC.beginPath();
    // oGC.save();
    // oGC.lineWidth = 15;
    // oGC.strokeStyle = '#000';
    // // // 在源图上
    // oGC.globalCompositeOperation = 'source-over';
    // oGC.lineCap = 'round';
    // oGC.lineJoin = 'round';
    // oGC.moveTo(x, y);
    // oGC.quadraticCurveTo(x, y, x1, y1);
    // oGC.stroke();
    // oGC.closePath();

    var oGC = this.ctx;
    oGC.closePath();
    oGC.beginPath();
    oGC.save();
    oGC.lineWidth = 15;
    oGC.strokeStyle = '#000';
    // 在源图上
    oGC.globalCompositeOperation = 'source-over';
    oGC.lineCap = 'round';
    oGC.lineJoin = 'round';
    oGC.moveTo(points[0].x, points[0].y);
    if (points.length > 1) {
      points.forEach(function(point, i, a) {
        if (i < a.length - 2) {
          let [midX, midY] = [
            (point.x + a[i + 1].x) / 2,
            (point.y + a[i + 1].y) / 2,
          ];
          oGC.quadraticCurveTo(point.x, point.y, midX, midY);
        }
      });
    }
    oGC.stroke();
    oGC.closePath();
  }
  // 渲染画笔时，已经绘制的点重绘，排除橡皮擦
  putImageDrawtoRenderPoints() {
    this.drawObjArr.forEach((drawObj) => {
      drawObj.drawWithCanvasAll(this.ctx, drawObj.type);
    });
  }
  //获取当前画布上的所有点，包含了橡皮擦的点，后期需要去除掉重叠的点
  getAlldrawPoints() {
    let arr = [];
    this.drawObjArr.forEach((drawObj) => {
      arr = [...arr, ...drawObj.points];
    });
    return arr;
  }
  putImageDraw(arr, pixel, s) {
    return new Promise((resolve, reject) => {
      let i = 0;
      let oGC = this.ctx;
      let timer = setInterval(() => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let { x, y } = arr[i];
        this.putImageDrawtoRenderPoints();
        oGC.drawImage(this.hbimg, x, y - 150, 150, 150);
        i++;
        if (i >= arr.length) {
          clearInterval(timer);
          i = 0;
          resolve(true);

          return;
        }
      }, s);
    });
  }
  putImageDraw2(arr, pixel, s) {
    return new Promise((resolve, reject) => {
      // let newarr = [];
      let i = 0;
      let oGC = this.ctx;
      // this.putDrawPoints.push({ points: newarr, pixel });
      let timer = setInterval(() => {
        // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        let { x, y } = arr[i];
        // newarr.push(arr[i]);
        // this.reloadObjects();
        oGC.drawImage(this.hbimg, x, y - 150, 150, 150);

        i++;

        if (i >= arr.length) {
          clearInterval(timer);
          i = 0;
          resolve(true);

          return;
        }

        // resolve(true);
      }, s);
    });
  }
  putImageDraw1(arr, pixel, s) {
    this.scale = 1;
    this.offsetX = 0;
    this.offsetY = 0;
    this.ctx.setTransform(
      this.scale,
      0,
      0,
      this.scale,
      this.offsetX,
      this.offsetY
    );
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    return new Promise((resolve, reject) => {
      let newarr = [];
      let i = 0;
      let oGC = this.ctx;
      this.putDrawPoints.push({ points: newarr, pixel });
      let timer = setInterval(() => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // this.reloadObjects();
        this.render(oGC);

        let { x, y } = arr[i];
        newarr.push(arr[i]);
        // this.loadPoints(this.putDrawPoints);
        oGC.drawImage(this.hbimg, x, y - 150, 150, 150);

        i++;

        if (i >= arr.length) {
          clearInterval(timer);
          i = 0;
          resolve(true);

          return;
        }

        // resolve(true);
      }, s);
    });
  }
}
