<template>
  <div class="popup-container" @touchstart="toClose" v-if="showModal">
    <transition
      name="bounce"
      appear-class="bounce-enter"
      appear
      appear-active-class="bounce-enter-active"
    >
      <div
        class="modal-container"
        @touchstart.stop
        :style="{ height, width }"
        v-if="show"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ['height', 'width'],
  data() {
    return {
      show: false,
      showModal: false,
    };
  },
  methods: {
    toShow(fun) {
      this.show = true;
      this.fun = fun;
    },
    toClose() {
      this.show = false;
    },
  },
  watch: {
    show: function(t, f) {
      t
        ? (this.showModal = true)
        : setTimeout(() => {
            this.showModal = false;
          }, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content:center;
  align-items: center;
  -webkit-align-items:center;
}
.modal-container {
//   // width: 200px;
//   // height: 100px;
// background: #fff;
//   // border-radius: 0.16rem;
  position: relative;
}
.header {
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
}

// 动画
.bounce-enter-active {
  animation: bounce 0.3s;
}
.bounce-leave-active {
  animation: bounce 0.3s reverse;
}
@keyframes bounce {
  0% {
    transform: scale(0);
  }
  // 50% {
  //   transform: scale(1.5);
  // }
  100% {
    transform: scale(1);
  }
}
</style>
