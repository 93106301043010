<template>
  <div class="draw-container">
    <div class="board" :class="isPc() ? 'deg-0' : 'deg-90'" @click="hiderange">
      <!-- 画布区域 -->
      <div id="box" @click="hiderange" class="boxs"></div>
      <img src="../../assets/images/draw/canvas.png" alt="" id="boxBg" />
      <!-- 返回 -->
      <img
        src="../../assets/images/draw/back.png"
        alt=""
        @click="goback"
        class="goBack"
      />
      <img src="../../assets/images/draw/flower.png" alt="" class="flower" />
      <div class="newBtn">
        <img
          src="../../assets/images/draw/pre.png"
          alt=""
          class="pre"
          @click="eventType(2)"
        />
        <img
          src="../../assets/images/draw/resetdo.png"
          alt=""
          class="resetdo"
          @click="eventType(3)"
        />
        <img
          src="../../assets/images/draw/clear.png"
          alt=""
          class="clear"
          @click="eventType(4)"
        />
        <img
          src="../../assets/images/draw/finish.png"
          alt=""
          class="finish"
          @click="showPopup"
        />
      </div>
      <div class="btns" @click.stop="hiderange">
        <!-- <div @touchstart="eventType(0)">画笔</div>
        <div @touchstart="eventType(1)">橡皮擦</div> -->
        <!-- <div @touchstart="eventType(2)">
          <img src="@/assets/images/back.png" alt="" />
        </div>
        <div @touchstart="eventType(3)">
          <img src="@/assets/images/go.png" alt="" />
        </div>
        <div @touchstart="eventType(4)">
          <img src="@/assets/images/close.png" alt="" />
        </div> -->
        <!-- <div @touchstart.stop="eventType(5, $event)" class="changePixel">
          大小
        </div> -->
        <div @click.stop>
          <range
            class="slider"
            v-show="showrange"
            :rangeInfo="rangeInfo"
            :style="`left:${rangeInfo.x}px;top:${rangeInfo.y}px`"
          ></range>
        </div>
      </div>
      <popup
        ref="popup"
        title="生成山水画"
        width="7.786667rem"
        height="4.56rem"
      >
        <div class="modal">
          <div class="title">生成山水画?</div>
          <mt-field label="" placeholder="请输入签名" v-model="sign" class="sign" state="" @input="changeInput"></mt-field>
          <div class="popup-btns">
            <div class="btn-box">
              <img
                src="../../assets/images/drawDialog/insert.png"
                alt=""
                @click="
                  () => {
                    this.eventType(4)
                    this.closePopup()
                  }
                "
              />
              <img
                src="../../assets/images/drawDialog/sure.png"
                alt=""
                @click.stop="drawHandler"
              />
            </div>
          </div>
          <img
            src="../../assets/images/drawDialog/close.png"
            alt=""
            class="closeDialog"
            @click="closePopup"
          />
        </div>
      </popup>
      <!-- </div> -->

      <!-- <img id="pen" src="../../assets/images/pen.png" mode="" /> -->
    </div>
  </div>
</template>

<script>
import canvas from '../../assets/js/canvas'
import drawObj from '../../assets/js/drawObj'
import range from '../../components/range'
import { toImgStyle,isPc } from '../../utils'
import popup from '../../components/popup'
import { Toast } from 'mint-ui'
import {computedBox} from '../../assets/js/cmputedBox'
export default {
  components: { range, popup },
  data() {
    return {
      client: [],
      clients: [],
      msg: 0,
      rangeInfo: {
        rangeValue: 5,
      },
      showrange: false,
      jobUrl: '',
      show: false,
      disArea: {},
      sign:'',
      stateStr:'',
      isPc:isPc
    }
  },
  created() {
    // window.screen.orientation.lock("landscape-primary");
  },
  watch: {
    'rangeInfo.rangeValue': function(t) {
      if (t) {
        this.c.pixel = t
      }
    },
  },
  mounted() {
    this.init()
  },

  methods: {
    
    init() {
      let board = document.getElementsByClassName('board')[0]
      // let z = window.innerHeight
      // board.style.width = z + 'px'
      document.getElementById('box').addEventListener('touchmove', function(e) {
        e.preventDefault();
      });

      let box = document.getElementById('box');

      let {w,h} = computedBox();

      if(this.isPc()){
        let tem = 0;
        tem = w;
        w = h ;
        h = tem;
      }

      box.style.width = w + 'px';
      box.style.height = h + 'px';
      box.style.marginLeft = -w / 2 + 'px';
      box.style.marginTop = -h / 2 + 'px';
      this.initBoxBg(w,h)

      let c;

      if(this.isPc()){
        c = new canvas(document.getElementById('box'), w, h, 1600 , 900);
      }else{
        c = new canvas(document.getElementById('box'), w, h, 900 , 1600);
      }
       

      c.pixel = 5;
      this.c = c;

      // 禁止区域比例
      let disWstance = 2.57
      let disHstance = 2.33
      let disW = w / disWstance
      let disH = h / disHstance
      let disY = 0
      let disX = w - disW
      this.disArea = {
        w: disW,
        h: disH,
        x: disX,
        y: disY,
      }
      this.c.initDisArea(this.disArea)
    },
    initBoxBg(w, h) {
      let boxBg = document.getElementById('boxBg')

      if(this.isPc()){
        let tem = 0;
        tem = w;
        w = h ;
        h = tem;
      }

      boxBg.style.width = h + 'px'
      boxBg.style.height = w + 'px'
      boxBg.style.marginLeft = -h / 2 + 'px'
      boxBg.style.marginTop = -w / 2 + 'px'
    },
    goback() {
      try { 
        this.$route.go(-1)
      } catch (error) {
        this.$router.replace('/home')
      }
    },
    hiderange() {
      this.showrange = false
      this.sign = ''
    },
    change() {
      if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'zh'
      } else {
        this.$i18n.locale = 'en'
      }
    },
    eventType(state, e) {
      switch (state) {
        case 0:
          this.c.type = 1
          break
        case 1:
          this.c.type = 0
          break
        case 2:
          this.c.undo()
          this.c.type = 1
          break
        case 3:
          this.c.redo()
          this.c.type = 1
          break
        case 4:
          this.c.clearPage()
          break
        case 5:
          this.showRange(e)
          break
        case 6:
          this.showPopup()
          break
      }
    },
    popupShow(data) {},
    showRange(e) {
      // this.rangeInfo.x = e.target.getBoundingClientRect().x + 50;
      // this.rangeInfo.y = e.target.getBoundingClientRect().y - 150;
      this.showrange = !this.showrange
    },
    showPopup() {
      if(this.c.drawObjArr.length==0){
        Toast({
          message: '请画图喔！',
          duration:1000,
          className: 'toast'
        });
        return;
      }
      this.$nextTick(() => {
        this.$refs.popup.toShow()
      })
    },
    closePopup() {
      this.$nextTick(() => {
        this.$refs.popup.toClose()
        this.sign = ''
      })
    },
    // 完成
    async drawHandler() {
      // let len = this.c.getAlldrawPoints().length;
      // // this.c.putImageDraw(arr);
      // let s = 3000 / len < 30 ? 3000 / len : 30;
      // let arr = this.c.drawObjArr;
      // let all = this.c.getAlldrawPoints();
      // this.c.putImageDraw(all, 15, s);
      // this.msg = arr.length;
      // for (let i = 0; i < arr.length; i++) {
      //   await this.c.putImageDraw(arr[i].points, arr[i].pixel, s);
      // }
      // this.c.putDrawPoints = [];
      // this.c.reloadObjects();
      if(!this.sign){
        Toast({
          message: '请输入签名！',
          duration:1000,
          className: 'toast'
        });
        this.stateStr = 'error'
        return
      }

      let canvas = this.c.getCopyCanvas()
      var imageAsDataURL = canvas.toDataURL('image/png')
      let points = this.c.getAllPoints()
      let obj = {
        canvas: imageAsDataURL,
        imgurl: {},
        points,
      }

      this.$Indicator.open('生成中...')
      let url = this.c.getImageUrl()
      let formdata = new FormData()
      formdata.append('file', toImgStyle(url, Date.now() + '.png'))
      let res = await this.$api.createJob(formdata)
      let shuimohuaId = res.data.id
      let extraData = {
        id: shuimohuaId,
        jobId: res.data.jobId,
        stickFigureData: JSON.stringify(this.c.drawObjArr),
        sign: this.sign
      }

      let job = await this.$api.saveJobExtraData(extraData)
      let timer = setInterval(async () => {
        let job = await this.$api.getJobState({
          id:shuimohuaId
        })
        if (job.data.state == 3) {
          clearInterval(timer)
          this.$Indicator.close()
          this.$router.push({
            path: '/showpic',
            query: {
              shuimohuaId: shuimohuaId,
            },
          })
        }
      }, 3000)
      this.closePopup()
    },

    // 撤销
    undoPage() {
      this.c.undo()
    },
    // 反撤销
    redoPage() {
      this.c.redo()
    },
    // 清空
    deletePage() {},
    changeInput(e){
      this.stateStr = !e?'error':'success'
    }
  },
}
</script>

<style lang="scss" scoped>
#can {
  position: absolute;
  z-index: 10;
  background: #fff;
}
.modal {
  font-family: 'XQzhaopaitiJF';
  overflow: hidden;
  transition: all 0s !important;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 7.786667rem;
    height: 4.56rem;
    background: url(../../assets/images/drawDialog/bg.png) no-repeat;
    background-size: 100% 100%;
  }
  .title {
    position: absolute;
    z-index: 2;
    font-size: 0.48rem;
    text-align: center;
    margin-top: .666667rem;
    width: 100%;
    height: 0.426667rem;
    font-size: 0.506667rem;
    font-family: 'XQzhaopaitiJF';
    font-weight: 400;
    color: #333333;
    line-height: 0.533333rem;
    padding: 0 0.266667rem;
  }
  .sign{
    position: absolute;
    z-index: 2;
    @include a-center;
    width: 5.24rem;
    height: 1rem;
    background: url(../../assets/images/drawDialog/border.png) no-repeat;
    background-size: 100% 100%;
  }
  .closeDialog {
    position: absolute;
    width: 0.586667rem;
    height: 0.586667rem;
    top: 0.306667rem;
    right: 0.36rem;
    z-index: 2;
  }
}
.popup-btns {
  position: absolute;
  z-index: 1000;
  bottom: 0.6rem;
  width: 100%;
  .btn-box {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content:center;
    img {
      position: relative;
      color: #fff;
      width: 2.56rem;
      height: 0.8rem;
    }
    img:nth-child(1) {
      margin-right: 0.213333rem;
    }
  }
}
.slider {
  z-index: 1000;
  position: absolute;
  width: 5.253333rem;
  height: 1.813333rem;
  background: powderblue;
  top: -65px;
  right: 0;
}

.draw-container {
  position: relative;
}
.board {
  background: url(../../assets/images/draw/bg.png) no-repeat 100%;
  background-size: 100% 100%;
}

#boxBg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  animation: fade-in 1.2s;
}
#box {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  background-color: transparent;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  .mask {
    width: 3.146667rem;
    height: 5.333333rem;
    // background: pink;
    background: #d9d9d9;
    // border: .026667rem solid;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    .text {
      transform: rotate(90deg);
      // height: 100vw;
      p {
        color: #333333;
        font-size: 0.373333rem;
        white-space: nowrap;
        font-family: 'XQzhaopaitiJF';
      }
    }
  }
}
.canvas {
}
.btns {
  // border: 0.066667rem solid green;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: center;
  // height: 40px;
  height: 1.066667rem;
  > div {
    margin-right: 0.346667rem;
    font-size: 0.64rem;
  }
  img {
    width: 0.906667rem;
    height: 0.96rem;
  }
}

#pen {
  position: relative;
  z-index: 9999;
  width: 0.666667rem;
  height: 0.666667rem;
  display: none;
}
.changePixel {
  position: relative;
  > div {
    position: absolute;
    z-index: 1000;
    /* transform: scale(0.5); */
    top: 0;
    width: 200px;
  }
}
.popup-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  // @include a-center;

  .btn {
    font-size: 0.186667rem;
    height: 0.666667rem;
    // border: 0.013333rem solid #b19e8e;
    width: 2.666667rem;
    margin-top: 0.133333rem;
  }
  .confim {
    background: #1976dc;
    color: #fff;
  }
}
@keyframes fade-in {  
    // transform: rotate(180deg);
    0% {opacity: 0}
    100% {opacity: 1}
} 
</style>
<style scoped lang="scss">
.goBack {
  position: absolute;
  width: 0.72rem;
  height: 0.68rem;
  left: 0.6rem;
  top: 0.133333rem;
}
.flower {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.933333rem;
  height: 2.933333rem;
}
.newBtn {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items:center;
  justify-content: center;
  -webkit-justify-content:center;
  position: absolute;
  width: 100%;
  // left: 2.666667rem;
  bottom: 0.133333rem;
  .pre,
  .resetdo,
  .clear {
    height: 0.826667rem;
    width: 2.053333rem;
    margin-right: 0.786667rem;
  }
  .finish {
    height: 0.906667rem;
    width: 3.48rem;
  }
}
::v-deep.mint-field .mint-cell-title{
  width:0;
}
::v-deep.sign{
  background-color: transparent;
}
::v-deep .mint-field-core{
  font-family: 'XQzhaopaitiJF' !important;
  text-align: center;
  background-color: transparent;
  font-size: .506667rem;
  padding: 0 .4rem;
  height: 1rem;
}
::v-deep.mint-field-clear{
  font-size: .506667rem;
}
::v-deep.mint-cell-wrapper{
  height: 1rem;
}
</style>
<style>




@media screen and (max-width: 767px) {

  /* 针对移动设备的CSS样式设置 */
  .toast{
    margin-left:20vw;
    transform: translate(0, -50%) rotate(90deg) !important;
    -webkit-transform: translate(0, -50%) rotate(90deg) !important;
  }
}

@media screen and (min-width: 768px) {

  /* 针对电脑设备的CSS样式设置 */
  .mint-indicator {
    transform: none !important;
    -webkit-transform: none !important;
  }

}

</style>
